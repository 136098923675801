import { AxiosResponse } from "axios";
import { ApiClient } from "./";
import { ICreatePaymentRequest } from "./api-types/ICreatePaymentRequest";
import { ICreatePaymentResponse } from "./api-types/ICreatePaymentResponse";
import { IHistoryRequest } from "./api-types/IHistoryRequest";
import { IPayment } from "./api-types/IPayment";
import { ICurrentMontoResponse } from "./api-types/ICurrentMontoResponse";

export class PacienteApi extends ApiClient {

  async getPaciente(id: string): Promise<AxiosResponse<IPayment | null>> {
    return await this.requestPost<IPayment, IHistoryRequest>(`payment/history`, {Id: id});
  }

  async PagarOnline(payload: ICreatePaymentRequest): Promise<AxiosResponse<ICreatePaymentResponse | null>> {
    return await this.requestPost<ICreatePaymentResponse,ICreatePaymentRequest>(`payment`, payload);
  }

  async CurrentMontos(): Promise<AxiosResponse<ICurrentMontoResponse | null>> {
    return await this.requestGet<ICurrentMontoResponse>(`payment/current-montos`);
  }

}
