import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import "./App.css";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
  ThemeOptions,
} from "@mui/material/styles";
import { PaymentProvider } from "../src/context/PaymentProvider";

import { PacienteCard } from "./components/Paciente";
import { CopyrightBand } from "./components/CopyrightBand";
import MainToolBar from "./components/MainToolBar";
import { ProfesionalPaper } from "./components/ProfesionalPaper";
import { PaymentForm } from "./components/PaymentForm";
import queryString from "query-string";

const theme = createTheme({
  typography: {
    body2: {
      color: "rgba(0, 0, 0, 0.6);",
    },
  },
});

function App() {
  const queryParams = queryString.parse(window.location.search);

  return (
    <div className="App">
      <PaymentProvider>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MainToolBar />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              {queryParams.id ? (
                <>
                  <PacienteCard />
                  <PaymentForm />
                </>
              ) : (
                <><Alert severity="error">La dirección no es correcta!</Alert></>
              )}
            </Box>
            <CopyrightBand sx={{ mt: 8, mb: 4 }} />
          </Container>
        </ThemeProvider>
      </PaymentProvider>
    </div>
  );
}

export default App;
