import axios, { AxiosResponse } from 'axios';

interface RequestProps<P, D = {}> {
  method: 'put' | 'post' | 'get' | 'delete';
  url: string;
  params?: P;
  data?: D; //AxiosRequestConfig
}

export class ApiClient {
  private readonly prefix: string;

  constructor({ prefix }: { prefix: string } = { prefix: '' }) {
    this.prefix = prefix;
  }

  get headers() {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json'  ,
      'Access-Control-Allow-Origin': '*'    
    };
  }

  private get env() {
    return {
      __domain: process.env.REACT_APP_Domain,
      __apiVersion: process.env.REACT_APP_ApiVersion, 
    };
  }

  private get apiBase() {
    return `${this.env.__domain}/${this.env.__apiVersion}${this.prefix}`;
  }

  requestGet<T, P = {}>(url: string, params?: P) {
    return this.requestDataApi<T, P>({ method: 'get', url, params });
  }

  requestDelete<T, P = {}>(url: string, params?: P) {
    return this.requestDataApi<T, P>({ method: 'delete', url, params });
  }

  requestPost<T, D, P = {}>(url: string, data?: D, params?: P) {
    return this.requestDataApi<T, P, D>({ method: 'post', url, data, params });
  }

  requestPut<T, D, P = {}>(url: string, data?: D, params?: P) {
    return this.requestDataApi<T, P, D>({ method: 'put', url, data, params });
  }  

  private getUrl(url: string) {
    const shouldIgnorePrefix = url.startsWith('$');
    const safeUrl = shouldIgnorePrefix ? url.replace('$', '') : url;

    if (shouldIgnorePrefix) {
      return `${this.env.__domain}/${this.env.__apiVersion}/${safeUrl}`;
    }

    return `${this.apiBase}/${url}`;
  }

  private request<T = {}, P = {}, D = {}>({
    method,
    params,
    url,
    ...config
  }: RequestProps<P, D>): Promise<AxiosResponse<T | null>> {
    return axios
      .request({
        method,
        params,
        url,
        headers: this.headers,
        ...config,
      })
      .catch(error => {      
        throw error;
      });
  }

  private async requestDataApi<T = {}, P = {}, D = {}>({
    method,
    url,
    params,
    data,
    ...config
  }: RequestProps<P, D>) {
    const filters = (params as unknown as { filters?: string })?.filters;

    return await this.request<T, P, D>({
        method,
        data,
        params: filters
          ? ({ ...params, filters: filters } as unknown as P)
          : params,
        url: this.getUrl(url),
        ...config,
      });    
  }

  
}
