import { IPaciente } from '../api/api-types/IPaciente';
import { IPayment } from '../api/api-types/IPayment';

type PaymentAction = 
    | { type: 'updatePayment', payload: IPayment }
    | { type: 'toggleTodo', payload: { id: string } };


export const PaymentReducer = ( state: IPayment, action: PaymentAction ): IPayment => {

    switch ( action.type ) {
        case 'updatePayment':
            return {
                ...state, 
                ...action.payload
            }

        case 'toggleTodo': 
            return {
                ...state,
                /*
                todos: state.todos.map( ({ ...todo }) => {
                    if( todo.id === action.payload.id ) {
                        todo.completed = !todo.completed;
                    }
                    return todo;
                }) */
            }

            
        default:
            return state;
    }

}